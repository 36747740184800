<template>
  <div class="app-container">
    <div class="add-container">
      <div class="add-title">发送邮件（揽件）</div>
    </div>
    <el-form label-width="120px" :model="postData" ref="postForm" :rules="rules">
      <el-form-item label="申请订单号：">
        <el-input placeholder="请从申请单列表获取" style="width:60%" v-model="postData.order_id"></el-input>
        <el-button type="primary" class="mlm" @click="search">查询</el-button>
      </el-form-item>
      <el-form-item label="邮件主题：" prop="title">
        <el-input placeholder="请填写" style="width:60%" v-model="postData.title"></el-input>
      </el-form-item>
      <el-form-item label="接收人邮箱：" prop="email">
        <el-input placeholder="请填写" style="width:60%" v-model="postData.email"></el-input>
      </el-form-item>
      <el-form-item label="接收人姓名：" prop="nickname">
        <el-input placeholder="请填写" style="width:60%" v-model="postData.nickname"></el-input>
      </el-form-item>
      <el-form-item label="邮件内容：" prop="content">
        <Quill
          v-model="postData.content"
        />
      </el-form-item>
      <el-form-item label="是否发送附件：" prop="files">
        <el-checkbox-group v-model="postData.files">
          <el-checkbox v-for="(item, index) in files" :key="index" :label="item.pdf">{{item.type == 1 ? '揽件面单.pdf' : '派送面单.pdf'}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="loading" @click="send">发送</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script type="text/javascript">
import Quill from '@/components/RichTextEditor/quill'
import order from '@/api/order'
import common from '@/api/common'
const postData = {
  content: '<p><span style="background-color: rgb(254, 254, 254);">感谢使用申通快递的服务！</span></p><p><span style="background-color: rgb(254, 254, 254);">Thank you for using STO Express!</span></p><p><span style="background-color: rgb(254, 254, 254);">我们收到了中华人民共和国驻瑞典大使馆的快递取件通知，我司供应商会尽快揽收您的文件，取件在1-3个工作日内完成。</span>&nbsp;<span style="background-color: rgb(254, 254, 254);">请您打印出邮件附件中的面单，粘贴到您的信封（务必粘牢），</span><span style="background-color: rgb(254, 254, 254); color: rgb(255, 0, 0);">信封尺寸不要超过长35厘米x宽25厘米x高2厘米</span><span style="background-color: rgb(254, 254, 254);">，超过此尺寸，我司会收取额外费用。如遇取件问题，请咨询：077-133 33 10</span></p><p><span style="background-color: rgb(245, 245, 245);">We have received the express delivery order from the Chinese Embassy/Consulate. Our supplier Postnord will collect your documents ASAP, and the pickup will be completed within&nbsp;1-3&nbsp;working days.</span>&nbsp;<span style="background-color: rgb(245, 245, 245);">Please print out the waybill attached to the email and paste it on your envelope/package (be sure to stick it firmly).</span></p><p><br></p><p><span style="background-color: rgb(254, 254, 254);">备注：</span></p><p><span style="background-color: rgb(254, 254, 254);">使领馆回邮我司会提供信封，来邮请自备信封</span></p><p><span style="color: rgb(255, 0, 0); background-color: rgb(254, 254, 254);">如有急件，可凭附件面单投递到离您最近的Postnord网点。</span></p>',
  nickname: '',
  title: '申通快递 STO Express',
  email: '',
  files: [],
}
export default {
  components: { Quill },
  data() {
    return {
      postData: { ...postData },
      files: [],
      rules: {
        title: [{ required: true, message: '必填项' }],
        nickname: [{ required: true, message: '必填项' }],
        email: [{ required: true, message: '必填项' }],
        content: [{ required: true, message: '必填项' }],
      },
      loading: false,
    }
  },
  created() {
  },
  methods: {
    search() {
      if (!this.postData.order_id) {
        this.$message.error('请填写订单号')
        return;
      }
      order.info(this.postData.order_id)
      .then(res => {
        this.postData = {
          ...this.postData,
          nickname: res.conn_user,
          email: res.conn_email,
          files: [],
        }
        this.files = res.pn_order || []
      })
    },
    send() {
      this.$refs.postForm.validate(valid => {
        if (!valid) return;
        this.loading = true
        let { files, ...body } = this.postData
        if (files.length) {
          body.files = files.join(',')
        }
        common.sendEmail(body)
        .then(() => {
          this.$message.success('发送成功')
          this.loading = false
          this.postData = { ...postData }
          this.files = []
        })
        .catch(() => {
          this.loading = false
        })
      })
    },
  }
}
</script>
